@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.modulor_dialog {
  .modulor_attachment_label {
    border-bottom: modulor-border();
    padding-bottom: modulor-unit(padding);
    table-layout: fixed;
    width: 100%;
  }

  .modulor_link__action_open {
    display: block;
    position: relative;
  }

  .modulor_link__action_open +
  .modulor_attachment_label {
    margin-top: 1em;
  }

  .modulor_attachment_label +
  .modulor_link__action_download {
    display: inline-block;
    margin-top: 1em;
  }

  .modulor_link__action_download +
  .modulor_auditor_footer {
    margin-top: 1em;
  }

  .modulor_attachment_label__group {
    > dl:first-child {
      > dt, > dd {
        &:before {
          content: " ";
          display: block;
          margin-top: modulor-unit(padding);
          padding-top: modulor-unit(padding);
          border-top: modulor-border();
        }
      }
    }
  }
}
