@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.application_form_module__sign_in {
  display: grid;
  grid-gap: 0;
  padding: 0;
  width: 100%;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }

  .simple_form {
    @include breakpoints {
      column-gap: $web-module-padding * 2;
      grid-template-columns: repeat(#{$columns / 2}, 1fr);
      grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
    }
    @include breakpoints (xs) {
      column-gap: 0;
      grid-column: 1 / span #{$columns * 2};
    }

    div.input.email {
      @include breakpoints {
        margin-bottom: $web-module-padding;
      }
      input {
        @include folio-std-medium;
        @include font-size(default);

        appearance: none;
        background: transparent;
        border: 0;
        border-radius: 0px;
        width: 100%;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid;
      }
    }

    div.boolean {
      display: inline-block;
      @include breakpoints {
        margin-right: $web-module-padding;
      }
      input {
        cursor: pointer;
        @include breakpoints {
          margin-right: $web-module-padding / 4;
        }
      }

      a {
        @include link(first_letter);
      }
    }

    input.btn {
      @include folio-std-medium;
      @include font-size(default);
      background: transparent;
      text-transform: uppercase;
      border: 0;
      cursor: pointer;
      display: block;
      margin: 0;
      padding: 0;
      &:active, &:focus {
        outline: none;
      }
      @include breakpoints {
        font-size: 2em;
        white-space: normal;
        text-align: left;
        line-height: 1.35;
        margin-top: $web-module-padding;
      }
    }
  }
}

.application_form_module__sign_in__success {
  font-size: 2em;
  line-height: 1.1;
  @include breakpoints {
    grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
  }
  @include breakpoints (xs) {
    grid-column: 1 / span #{$columns * 2};
  }
}

.application_form_module__sign_in__text {
  display: grid;
  grid-gap: 0;
  padding: 0;
  width: 100%;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }

  div {
    @include breakpoints {
      margin-bottom:  $web-module-padding * 1;
      grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      p {
        margin-bottom: $web-module-padding;
      }
    }
    @include breakpoints (xs) {
      grid-column: 1 / span #{$columns * 2};
    }
  }
}
