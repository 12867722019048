@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.individual_project_page__thumbnail {
  @include folio-std-medium;
  @include font-size(default);

  list-style: none;
  width: 100%;

  @include breakpoints {
    margin-bottom: $web-module-padding;
  }
}

.individual_project_page__title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  @include font-size(default);
}

.individual_project_page__date {
  @include semantic-date-time-tag(hide-month-if-same);
  @include semantic-date-time-tag(hide-year-if-same);
}

.individual_project_page__description {
  margin: auto auto 0 auto;
  color: inherit;
  max-width: 100%;
  display: block;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    @include link(first_letter);
    color: inherit;
    text-decoration: none;
  }
}

.individual_project_page__site {
  max-width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  @include link(first_letter);
  a {
    text-decoration: none;
    color: inherit;
  }
}

.individual_project_page__link {
  @include link(first_letter);
}

.individual_project_page__thumbnail__image__wrapper {
  @include breakpoints {
    margin: 0  0 $web-module-padding * .425  0;
  }
}
