@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.modulor_date_picker__picker {
  @include modulor-border(div);
  @include modulor-box-shadow;
  @include modulor-font-size(default);
  @include modulor-font;

  &.open {
    z-index: modulor-z-index(ui, date-picker) !important;
  }

  .flatpickr-month {
    @include modulor-font;
    background: modulor-color(white);
    height: 3em;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover {
      path {
        fill: modulor-color(blue);
      }
    }
  }

  .flatpickr-current-month {
    @include modulor-font-size(large);
  }

  .flatpickr-weekdays {
    background: modulor-color(white);
    height: 2em;
  }

  .flatpickr-weekday {
    @include modulor-font-size(default);
    background: none;
    color: modulor-color(gray);
    font-weight: normal;
  }

  .flatpickr-day {
    @include modulor-font;
    font-weight: normal;
    border: 0;
    border-radius: 0;

    &:hover {
      background: modulor-color(gray-extra-light);
      border: 0;
      border-radius: 0;
    }

    &.selected {
      background: modulor-color(blue);
      border: 0;
      border-radius: 0;
    }

    &.today {
      background: modulor-color(yellow);
      border: 0;
      border-radius: 0;
      &:hover { color: modulor-color(black); }
    }

    &.prevMonthDay,
    &.nextMonthDay {
      color: modulor-color(gray);
    }
  }
}
