@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.application_form_module__preview__ol {
  margin: 0;
  padding: 0;
  counter-reset: item;
  ol {
    counter-increment: item;
    counter-reset: counter;
    @include breakpoints {
      margin-bottom: $web-module-padding * 4;
    }
  }

  pre {
    font-family: Courier New, Courier, monospace;
    white-space: normal;
  }

  dl, dt, dd {
    margin: 0;
    padding: 0;
  }
}

.application_form_module__preview__section {
  display: grid;
  grid-gap: 0;
  padding: 0;
  width: 100%;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
    margin-bottom: $web-module-padding * 2;
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }
}

.application_form_module__preview__section__title {
  display: grid;
  grid-gap: 0;
  margin: 0;
  padding: 0;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }
  h1 {
    text-transform: uppercase;
    display: list-item;
    list-style-position: inside;
    @include breakpoints {
      grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      margin-bottom: $web-module-padding * 2;
    }
  }
}

.application_form_module__preview__section__subtitle {
  counter-increment: counter;
  &:before {
    content: counters(item, ".") "." counters(counter, ".") " ";
    display: block;
  }
  @include breakpoints {
    grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
  }
  @include breakpoints (xs) {
    grid-column: 1 / span #{$columns * 2};
  }
}

.application_form_module__preview__section__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  @include breakpoints () {
    grid-column: 1 / span #{$columns};
  }
}

.application_form_module__preview__section__list {
  padding: 0;
  list-style: disc;
  list-style-position: inside;
  @include breakpoints {
    margin-top: $web-module-padding;
    grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
  }
  @include breakpoints (xs) {
    grid-column: 1 / span #{$columns * 2};
  }
}

.application_form_module__preview__section__fieldset__fullwidth {
  border: 0;
  margin: 0;
  padding: 0;
  @include breakpoints () {
    grid-column: 1 / span #{$columns};
  }
}

.application_form_module__preview__confirm {
  padding: 0;
  @include breakpoints() {
    text-indent: calc(50% + #{$web-module-padding});
  }
  @include breakpoints(xs) {
    text-indent: 0;
  }
}

.application_form_module__nav {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  background-color: color(pink);
  @include breakpoints {
    text-indent: calc(50% + #{$web-module-padding});
    padding: $web-module-padding;
  }
  @include breakpoints(xs) {
    text-indent: 50%;
    column-gap: 0;
  }

  a {
    @include link(first_letter);
    margin-left: .5em;
  }
}

.application_form_module__link_to__save {
  position: relative;

  &[data-state] {
    visibility: hidden;
    pointer-events: none;

    &:first-letter {
      background: none;
    }

    &:before {
      position: absolute;
      left: 0;
      visibility: visible;
    }
  }
}
