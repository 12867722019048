@import '../variables/typography';

@mixin breakpoints($names: map-keys($breakpoints)) {
  @each $name in $names {
    $breakpoint: map-get($breakpoints, $name);
    $columns: map-get($breakpoint, columns) !global;
    $name: "#{$name}" !global;
    $unit: 100%/$columns !global;
    $query: "#{map-get($breakpoint, exclusive_query)}" !global;

    // breakpoints
    $typography: map-get($typography-breakpoints, $name) !global;

    // derived values
    $default-line-height: map-deep-get($typography, default, line-height) !global;
    $web-module-padding: map-deep-get($typography, default, line-height) !global;
    $web-module-margin-bottom: map-deep-get($typography, default, line-height) * 2 !global;

    @if str-length($query) > 0 { @media #{$query} { @content; } }
    @else { @content; };
  }
}
