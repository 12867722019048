@mixin underline-for($size: default, $color: color(black)) {
  @if $size == default {
    @include underline(.01em, .1em, $color);
  }
}

@mixin underline-for-white($size: default, $color: color(white)) {
  @if $size == default {
    @include underline(.01em, .1em, white);
  }
}

@mixin remove-underline {
  background-color: inherit;
  background-size: initial;
  background-repeat: initial;
  background-position: initial;
  background-image: none;
}
