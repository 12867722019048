@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.application_form_module__form__ol {
  margin: 0;
  padding: 0;
  counter-reset: item;
  ol {
    counter-increment: item;
    counter-reset: counter;
    @include breakpoints {
      margin-bottom: $web-module-padding * 4;
    }
  }
}

.application_form_module__form__section {
  display: grid;
  grid-gap: 0;
  padding: 0;
  width: 100%;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
    margin-bottom: $web-module-padding * 2;
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }
}

.application_form_module__form__section__title {
  display: grid;
  grid-gap: 0;
  margin: 0;
  padding: 0;
  @include breakpoints {
    column-gap: $web-module-padding * 2;
    grid-template-columns: repeat(#{$columns}, 1fr);
  }
  @include breakpoints(xs) {
    column-gap: 0;
  }
  h1 {
    text-transform: uppercase;
    display: list-item;
    list-style-position: inside;
    @include breakpoints {
      grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      margin-bottom: $web-module-padding * 2;
    }
  }
}

.application_form_module__form__section__subtitle {
  counter-increment: counter;
  &:before {
    content: counters(item, ".") "." counters(counter, ".") " ";
    display: block;
  }
  @include breakpoints {
    grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
  }
  @include breakpoints (xs) {
    grid-column: 1 / span #{$columns * 2};
  }
}

.application_form_module__form__section__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  @include breakpoints () {
    grid-column: 1 / span #{$columns};
  }

  dl {
    display: grid;
    grid-gap: 0;
    width: 100%;
    margin: 0;

    @include breakpoints {
      column-gap: $web-module-padding * 2;
      grid-template-columns: repeat(#{$columns}, 1fr);
    }
    @include breakpoints(xs) {
      column-gap: 0;
      margin-top: $web-module-padding;
    }
    dt {
      @include breakpoints {
        grid-column: 1 / span #{$columns / 2};
      }
      @include breakpoints (xs) {
        grid-column: 1 / span #{$columns};
      }
    }
    dd {
      margin: 0;
      @include breakpoints {
        grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      }
      @include breakpoints (xs) {
        grid-column: 1 / span #{$columns * 2};
      }
    }
  }

  .input {
    display: grid;
    grid-gap: 0;
    width: 100%;

    @include breakpoints {
      column-gap: $web-module-padding * 2;
      grid-template-columns: repeat(#{$columns}, 1fr);
    }
    @include breakpoints(xs) {
      column-gap: 0;
      margin-top: $web-module-padding;
    }

    label {
      @include folio-std-medium;
      @include font-size(default);

      @include breakpoints {
        grid-column: 1 / span #{$columns / 2};
      }
      @include breakpoints (xs) {
        grid-column: 1 / span #{$columns};
      }
    }

    input, option {
      appearance: none;
      background: transparent;
      border: 0;
      border-radius: 0px;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid;

      &-internal-autofill-selected {
        background: red;
      }

      @include folio-std-medium;
      @include font-size(default);

      @include breakpoints {
        grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      }
      @include breakpoints (xs) {
        grid-column: 1 / span #{$columns * 2};
      }
    }

    select {
      border: 0;
      border-radius: 0px;
      padding: 0;
      margin: 0;
      margin-top: .2em;
      margin-bottom: .2em;

      @include folio-std-medium;
      @include font-size(default);

      @include breakpoints {
        width: calc(100% + #{$columns - $web-module-padding * 3});
      }

      @include breakpoints (s) {
        width: 100%;
        grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
      }

    }

    .date, .country {
      @include breakpoints {
        margin-top: $web-module-padding;
      }
      @include breakpoints (xs) {
        margin-top: 0;
        margin-bottom: $web-module-padding / 1;
      }
    }

    &.attachment {
      > label,
      .simple_form_attachments__attachment__col__fields  {
        display: none;
      }
      .simple_form_attachments  {
        grid-column: span 8;

        .modulor_attachment_label__subject_type,
        .modulor_attachment_label__file_type {
          display: none;
        }

        .modulor_attachment_label__file_name {
          a {
            @include folio-std-medium;
            @include font-size(default);
            @include link(first_letter);
          }
        }
      }

      span.error {
        grid-column: 1 / span 8;
      }

      .simple_form_attachments__attachment__col  {
        padding: 0;
        dl { display: block; }
      }

      .simple_form_attachments__attachment__col__file_info  {
        padding-left: 1em;
        padding-right: 1em;
      }

      .modulor_attachment_low_resolution_label {
        display: none;
      }

      .simple_form_attachments__button__delete  {
        border: 0;
        background: none;
        margin: 0;
        padding: 0;
        display: inline;
        cursor: pointer;
        color: inherit;
        @include folio-std-medium;
        @include font-size(default);
      }
    }
  }
}

.application_form_module__form__section__list {
  padding: 0;
  list-style: disc;
  list-style-position: inside;
  @include breakpoints {
    margin-top: $web-module-padding;
    grid-column: #{$columns / 2 + 1} / span #{$columns / 2};
  }
  @include breakpoints (xs) {
    grid-column: 1 / span #{$columns * 2};
  }
}

.application_form_module__form__section__fieldset__fullwidth {
  border: 0;
  margin: 0;
  padding: 0;
  @include breakpoints () {
    grid-column: 1 / span #{$columns};
  }
  .input {
    label, textarea {
      @include folio-std-medium;
      @include font-size(default);

      display: block;
      width: 100%;
    }
    textarea {
      appearance: none;
      background: transparent;
      border-radius: 0px;
      border: 0;
      border: 1px solid;
      margin: 0;
      padding: 0;
      resize: none;
    }
  }

  span.error {
    grid-column: initial;
    @include breakpoints {
      text-indent: calc(50% + #{$web-module-padding});
    }
  }
}

.application_form_module__form__confirm {
  padding: 0;
  @include breakpoints() {
    text-indent: calc(50% + #{$web-module-padding});
  }
  @include breakpoints(xs) {
    text-indent: 0;
  }

  input {
    display: inline;
    margin-right: .5em;
  }

  input.btn {
    @include folio-std-medium;
    @include font-size(default);
    background: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    &:active, &:focus {
      outline: none;
    }
    @include breakpoints() {
      font-size: 2em;
      white-space: normal;
      text-align: left;
      line-height: 1.35;
      margin-left: calc(50% + #{$web-module-padding});
      margin-top: $web-module-padding;
    }
    @include breakpoints(xs) {
      margin-left: 0;
    }
  }
}

.application_form_module__nav {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  background-color: color(pink);
  @include breakpoints {
    text-indent: calc(50% + #{$web-module-padding});
    padding: $web-module-padding;
  }
  @include breakpoints(xs) {
    text-indent: 50%;
    column-gap: 0;
  }

  input.btn, a {
    @include folio-std-medium;
    @include font-size(default);
    background: transparent;
    border: 0;
    display: inline-block;
    text-indent: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;
    white-space: normal;
    &:active, &:focus {
      outline: none;
    }
  }

  a {
    @include link(first_letter);
    margin-left: .5em;
  }
}

.application_form_module__link_to__save {
  position: relative;

  &[data-state] {
    visibility: hidden;
    pointer-events: none;

    &:first-letter {
      background: none;
    }

    &:before {
      position: absolute;
      left: 0;
      visibility: visible;
    }
  }

  &[data-state="saving"] {
    &:before { content: attr(data-saving); }
  }

  &[data-state="error"] {
    color: color(red);
    &:before { content: attr(data-error); }
  }
  &[data-state="success"] {
    color: color(green);
    &:before { content: attr(data-success); }
  }
}
