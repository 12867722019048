@charset "UTF-8";
.page_list_module__content {
  font-family: 'Folio Std Medium', sans-serif;
  font-weight: normal;
  font-style: normal; }
  .page_list_module__content em, .page_list_module__content i {
    font-style: normal; }
  @media all and (max-width: 767px) {
    .page_list_module__content {
      font-size: 2.1rem;
      line-height: 2.8rem;
      letter-spacing: 0.02em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .page_list_module__content {
      font-size: 2.1rem;
      line-height: 2.8rem;
      letter-spacing: 0.02em; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .page_list_module__content {
      font-size: 2.1rem;
      line-height: 2.8rem;
      letter-spacing: 0.02em; } }
  @media (min-width: 1200px) {
    .page_list_module__content {
      font-size: 2.1rem;
      line-height: 2.8rem;
      letter-spacing: 0.02em; } }
  .page_list_module__content ul, .page_list_module__content ol {
    list-style: none;
    margin: 0;
    padding: 0; }
    .page_list_module__content ul > li, .page_list_module__content ol > li {
      margin: 0;
      padding: 0; }
  .page_list_module__content a {
    color: inherit;
    text-decoration: none; }
  .page_list_module__content li:not(.page_list_module__page__is_current) > a {
    text-decoration: none;
    color: black;
    display: inline-block; }
    .page_list_module__content li:not(.page_list_module__page__is_current) > a:first-letter {
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.01em, black 0.01em, black 0.11em, rgba(0, 0, 0, 0) 0.11em); }
    .page_list_module__content li:not(.page_list_module__page__is_current) > a:hover {
      display: inline;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.01em, black 0.01em, black 0.11em, rgba(0, 0, 0, 0) 0.11em); }
      .page_list_module__content li:not(.page_list_module__page__is_current) > a:hover:first-letter {
        background-color: inherit;
        background-size: initial;
        background-repeat: initial;
        background-position: initial;
        background-image: none; }
  @media print {
    .page_list_module__content {
      display: none; } }

.page_list_module__page__is_current > a {
  text-decoration: none;
  color: black;
  text-decoration: none;
  -webkit-print-color-adjust: exact;
  background-size: 100%;
  background-repeat: repeat-x;
  background-position: 100% 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.01em, black 0.01em, black 0.11em, rgba(0, 0, 0, 0) 0.11em); }

body[data-path=FAQ] .page_list_module__page:first-of-type {
  display: none; }
  @media all and (max-width: 767px) {
    body[data-path=FAQ] .page_list_module__page:first-of-type {
      display: table; } }

@media all and (max-width: 767px) {
  body[data-path=FAQ] .page_list_module__page {
    display: none; } }

@media all and (max-width: 767px) {
  body[data-path=FAQ] .page_list_module__page:first-of-type {
    display: none; }
  body[data-path=FAQ] .page_list_module__page:not(:first-of-type) {
    display: table; } }

@media (min-width: 768px) and (max-width: 991px) {
  body[data-path^="FAQ/"] .page_list_module__page:first-of-type {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199px) {
  body[data-path^="FAQ/"] .page_list_module__page:first-of-type {
    display: none; } }

@media (min-width: 1200px) {
  body[data-path^="FAQ/"] .page_list_module__page:first-of-type {
    display: none; } }

@media all and (max-width: 767px) {
  body[data-path^="FAQ/"] .page_list_module__page:first-of-type:before {
    content: "All "; }
  body[data-path^="FAQ/"] .page_list_module__page:first-of-type:after {
    content: "’s"; }
  body[data-path^="FAQ/"] .page_list_module__page:not(:first-of-type) {
    display: none; } }
