@mixin link($type, $size: default) {
  text-decoration: none;
  color: color(black);

  @if $type == first_letter {
    display: inline-block;

    &:first-letter {
      @include underline-for(default, black);
    }

    &:hover {
      display: inline;
      &:first-letter { @include remove-underline; }
      @include underline-for(default);
    }
  }

  @if $type == first_letter_white {
    display: inline-block;
    color: white;

    &:first-letter {
      @include underline-for-white(default);
    }

    &:hover {
      display: inline;
      &:first-letter { @include remove-underline; }
      @include underline-for-white(default);
    }
  }

  @if $type == underline {
    @include underline-for(default);
  }

  @if $type == underline_white {
    @include underline-for-white(default);
  }
}
