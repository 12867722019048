[data-repeat],
[data-repeat-clone] {
  background: inherit;
  overflow: hidden;
  display: block; }
  @media all and (max-width: 767px) {
    [data-repeat],
    [data-repeat-clone] {
      height: 2.8rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [data-repeat],
    [data-repeat-clone] {
      height: 2.8rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    [data-repeat],
    [data-repeat-clone] {
      height: 2.8rem; } }
  @media (min-width: 1200px) {
    [data-repeat],
    [data-repeat-clone] {
      height: 2.8rem; } }
