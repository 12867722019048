@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.lightbox {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;

  .modulor_attachment {
    margin: 0 auto;
  }

  figure {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    cursor: pointer;

    position: absolute;
    transform: translate(-50%, -50%);

    figcaption {
      display: none;
    }

    &:first-of-type {
      @include breakpoints() {
        clip-path: inset(0% calc(50% - #{$web-module-padding}) 0% 0%);
      }
      animation: animation_left .15s ease-out forwards;
    }

    &:last-of-type {
      clip-path: inset(0% 0% 100% 50%);
      animation: animation_right .125s .2s ease-out forwards;
    }

    img {
      object-fit: contain;

      @include breakpoints() {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@keyframes animation_left {
  from {
    clip-path: inset(0% calc(50% - #{$web-module-padding}) 100% 0%)
  }
  to {
    @include breakpoints() {
      clip-path: inset(0% calc(50% - #{$web-module-padding}) 0% 0%);
    }
  }
}

@keyframes animation_right {
  from {clip-path: inset(0% 0% 100% 50%);}
  to {clip-path: inset(0% 0% 0% 50%);}
}
