@mixin semantic-date-time-tag($type) {
  @if $type == hide-month-if-same {
    .same_month {
      .from {
        .month {
          display: none;
        }
      }
    }
  }

  @if $type == hide-year-if-same {
    .same_year {
      .from {
        .year {
          display: none;
        }
      }
    }
  }
}
