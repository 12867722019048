@import 'partials';

$breakpoints: (xs: (columns: 2, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (admission: Admission, admission_requirements: Admission/Requirements, application_form: Admission/Application-Form, contact: Contact, contact_phdarts: Contact/PhDArts, dissertations: Dissertations, doctoral_students: Doctoral-Students, faq: FAQ, individual_projects: Individual-Projects, news: News, phdarts: PhDArts, phdarts_about: PhDArts/About-PhDArts, programme: Programme, programme_upcoming: Programme/Upcoming, programme_past: Programme/Past, root: Index, supervisors: Supervisors);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
body[data-path^="#{path(individual_projects)}"] {
  .individual_project_list_module__content[data-year] {
    .individual_project_list_module__year {
      display: none;

      @media print {
        display: initial !important;
      }
    }

    .individual_project_list_module__year__selected,

    .individual_project_list_module__year__selected +
      .individual_project_list_module__year,

    .individual_project_list_module__year__selected +
      .individual_project_list_module__year +
      .individual_project_list_module__year,

    .individual_project_list_module__year__selected +
      .individual_project_list_module__year +
      .individual_project_list_module__year +
      .individual_project_list_module__year {
      display: block;
    }

    @include breakpoints(m s xs) {
      .individual_project_list_module__year__selected +
        .individual_project_list_module__year +
        .individual_project_list_module__year +
        .individual_project_list_module__year {
        display: none;
      }
    }

    @include breakpoints(s xs) {
      .individual_project_list_module__year__selected +
        .individual_project_list_module__year +
        .individual_project_list_module__year {
        display: none;
      }
    }

    @include breakpoints(xs) {
      .individual_project_list_module__year__selected +
        .individual_project_list_module__year {
        display: none;
      }
    }

    .individual_project_list_module__nav {
      display: none;

      @include breakpoints(l) {
        &[data-breakpoint="l"] { display: block; }
      }
      @include breakpoints(m) {
        &[data-breakpoint="m"] { display: block; }
      }
      @include breakpoints(s) {
        &[data-breakpoint="s"] { display: block; }
      }
      @include breakpoints(xs) {
        &[data-breakpoint="xs"] { display: block; }
      }

      @include breakpoints() {
        margin-bottom: $web-module-padding;
      }

      @media print {
        display: none !important;
      }
    }

    .individual_project_list_module__link_to__year {
      &:not(.individual_project_list_module__link_to__year__selected) {
        @include link(first_letter);
      }
      &:not(:last-child) {
        @include breakpoints() {
          margin-right: $web-module-padding / 1.35;
        }
      }
    }

    .individual_project_list_module__link_to__year__selected {
      @include link(underline);
    }
  }
}
